.home-nav {
    font-family: "Nunito Sans", sans-serif;
  }
  
  .section-title {
    color: #333;
    margin-bottom: 20px;
  }
  
  .list-group-item {
    border: none;
    padding: 10px 20px;
    background-color: #f8f9fa;
  }
  
  .form-container {
    min-height: 540px;
    background-color: #fff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  .form-container-r {
    max-height: 700px;
    background-color: #fff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  
  .form-control:focus {
    border-color: #d48336;
    box-shadow: 0 0 0 0.2rem rgba(212, 131,54 , 0.25);
  }
  
  .btn-primary {
    background-color: #d48336;
    border-color: #d48336;
  }
  
  .btn-primary:hover {
    background-color: #d48336;
    border-color: #d48336;
    color:white;
  }
  
  .row-equal-height {
    display: flex;
    align-items: stretch;
  }
  
  .card {
    border: none;
  }
  
  .card-body {
    padding: 2rem;
  }
/*   
  .card-header {
    background-color: #007bff;
    color: white;
    padding: 1rem 2rem;
    border-bottom: none;
    border-radius: 8px 8px 0 0;
  }
  
  .card-header h2 {
    margin: 0;
  } */
  .card-header {
    margin-top: -20px !important;
    padding: .2rem !important; /* Adjust padding to fit properly */
  }
  
  .card-header h2 {
    margin: 0 auto;
    font-size: 1.5rem !important; /* Adjust font size for mobile if needed */
  }
  
  /* PartnerWithUs.css */

.form-floating {
    position: relative;
    margin-bottom: 1rem;
  }
  
  .form-floating input,
  .form-floating select {
    width: 100%;
    padding: 1rem 0.75rem;
    border: 1px solid #ced4da;
    border-radius: 4px;
    transition: border-color 0.2s, padding-top 0.2s;
  }
  
  .form-floating input:focus,
  .form-floating select:focus {
    outline: none;
    border-color: rgba(212, 131,54);;

    padding-top: 1.5rem;
  }
  
  .form-floating label {
    position: absolute;
    top: 50%;
    left: 0.75rem;
    transform: translateY(-50%);
    background-color: #fff;
    padding: 0 0.25rem;
    color: #6c757d;
    pointer-events: none;
    transition: all 0.2s ease-in-out;
  }
  
  .form-floating input:focus ~ label,
  .form-floating input:not(:placeholder-shown) ~ label,
  .form-floating select:focus ~ label,
  .form-floating select:not([value=""]) ~ label {
    top: 0;
    left: 0.75rem;
    font-size: 0.75rem;
    color: rgba(212, 131,54);;
  }
  
  .row-equal-height {
    display: flex;
    flex-wrap: wrap;
  }
  
  .row-equal-height > [class*='col-'] {
    display: flex;
    flex-direction: column;
  }
  
  .form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .h-100 {
    height: 100%;
  }
  
  @media (max-width: 767.98px) {
    .form-container {
      margin-top: 20px !important;
      height: 30rem !important;
      max-height: 30rem !important;
    }
    .form-container-r {
      margin-top: 20px !important;
      height: 40rem !important;
      min-height: 75rem !important;
    }
  
    .row-equal-height {
      flex-direction: column;
    }
  }
  